import { parseDef } from "../parseDef.mjs";
export const parseOptionalDef = (def, refs) => {
  if (refs.currentPath.toString() === refs.propertyPath?.toString()) {
    return parseDef(def.innerType._def, refs);
  }
  const innerSchema = parseDef(def.innerType._def, {
    ...refs,
    currentPath: [...refs.currentPath, 'anyOf', '1']
  });
  return innerSchema ? {
    anyOf: [{
      not: {}
    }, innerSchema]
  } : {};
};
