export function parseNativeEnumDef(def) {
  const object = def.values;
  const actualKeys = Object.keys(def.values).filter(key => {
    return typeof object[object[key]] !== 'number';
  });
  const actualValues = actualKeys.map(key => object[key]);
  const parsedTypes = Array.from(new Set(actualValues.map(values => typeof values)));
  return {
    type: parsedTypes.length === 1 ? parsedTypes[0] === 'string' ? 'string' : 'number' : ['string', 'number'],
    enum: actualValues
  };
}
