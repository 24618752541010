<div class="flex flex-col min-h-[200px] h-fit max-h-[80vh] bg-gray-50">

  <!-- Header -->
  <header class="sticky top-0 z-50 bg-white border-b border-gray-100">
    <div class="container mx-auto px-4 py-4">
      <div class="flex items-center justify-between">
        <!-- Lado esquerdo com info do bot -->
        <div class="flex items-center gap-4">
          <div class="relative">
            <div class="p-2.5 bg-primary/10 rounded-xl">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
            </div>
            <span class="absolute bottom-0 right-0 block h-3 w-3 rounded-full bg-green-500 ring-2 ring-white"></span>
          </div>
          
          <div class="flex flex-col">
            <h1 class="text-lg font-bold text-gray-800 leading-tight">{{chatConfig.botName}}</h1>
            <p class="text-sm text-gray-500">{{chatConfig.botDescription}}</p>
          </div>
        </div>
        
        <!-- Lado direito com ações -->
        <div class="flex items-center gap-2">
          <button (click)="navigateToProductsList()" 
                  class=" p-2 hover:bg-gray-50 rounded-xl transition-colors duration-200" aria-label="Botão que realiza a navegaçao para a lista de produtos">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
            </svg>
          </button>
          
          <button class=" p-2 hover:bg-gray-50 rounded-xl transition-colors duration-200" aria-label="Botão que realiza a abertura do menu de configurações do chat">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </header>
  
  
  

  <!-- Chat Container -->
  <main class="flex-1 overflow-hidden flex flex-col container mx-auto px-4 md:px-6 lg:px-8">
    <!-- Empty State -->
    <section *ngIf="messages.length === 0" class="flex-1 flex flex-col justify-center items-center text-center py-12">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 text-primary mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <h2 class="text-2xl font-semibold text-gray-700 mb-2">Bem-vindo</h2>
      <p class="text-gray-500 max-w-md">Como posso ajudar você hoje? Estou aqui para responder suas perguntas e encontrar as melhores ofertas.</p>
    </section>
    <!-- Atualize a section de mensagens -->
    <section #chatContainer 
              class="flex-1 overflow-y-auto space-y-4 py-4 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-primary scroll-smooth">
      <div *ngFor="let message of messages; trackBy: trackByMessages" 
           class="flex w-full"
           [ngClass]="message.sender === 'user' ? 'justify-end' : 'justify-start'">
        <div class="max-w-[90%] md:max-w-[70%]">
          <div class="px-4 py-3 rounded-xl shadow-md transition-all 
                      text-sm md:text-base
                      " 
               [ngClass]="message.sender === 'user' ? 
                          'bg-primary text-white' : 
                          'bg-white text-gray-800 border border-gray-100'">
            {{ message.text }}
          </div>
          <!-- Product Section - Layout Horizontal Responsivo -->
          <div *ngIf="message.products && message.products.length > 0" 
               class="mt-3 flex flex-col gap-3">
            <div *ngFor="let product of message.products; trackBy: trackByProducts" 
                 class="bg-white rounded-xl shadow-soft overflow-hidden 
                        transform transition-default hover:-translate-y-1 
                        border border-gray-100 flex h-28 sm:h-32">
              
              <!-- Imagem do Produto -->
              <div class="relative w-24 sm:w-32 flex-shrink-0">
                <img [src]="product.thumbnail" 
                     [alt]="product.name" 
                     class="w-full h-full object-cover"
                />
                <div *ngIf="product.discount > 0" class="absolute top-1 left-1 bg-accent text-white px-1.5 py-0.5 sm:px-2 sm:py-1 rounded-lg text-xs font-semibold">
                  -{{product.discount}}%
                </div>
              </div>

              <!-- Informações do Produto -->
              <div class="flex-1 p-2 sm:p-3 flex flex-col justify-between min-w-0">
                <div>
                  <h3 class="font-bold text-xs sm:text-sm text-text truncate max-w-full">{{product.name}}</h3>
                  <div class="flex items-center gap-1 sm:gap-2 mt-0.5 sm:mt-1 cursor-pointer hover:opacity-80 transition-opacity" 
                  (click)="openStoreLink(product.store.link)" 
                  role="button" 
                  [attr.aria-label]="'Visitar loja ' + product.store.name">
               <img [src]="product.store.thumbnail" 
                    [alt]="product.store.name" 
                    class="w-3 h-3 sm:w-4 sm:h-4 rounded-full"
               />
               <span class="text-[10px] sm:text-xs text-text-muted truncate">{{product.store.name}}</span>
             </div>                                                                                   
                </div>

                <!-- Preços e CTA -->
                <div class="flex items-center justify-between">
                  <div  class="flex flex-col min-w-0">
                    <span *ngIf="product.discount > 0"  class="text-[10px] sm:text-xs text-text-muted line-through truncate">
                      {{formatPrice(product.priceFrom || product.price)}}
                    </span>
                    <span class="text-xs sm:text-sm font-bold text-primary">
                      {{formatPrice(product.price)}}
                    </span>
                    <span *ngIf="product.installment" class="text-[10px] sm:text-xs text-text-muted truncate">
                      {{product.installment.quantity}}x de {{formatPrice(product.installment.value)}}
                    </span>
                  </div>
                  
                  <button (click)="openProductLink(product)"
                          class="bg-primary hover:bg-primary-dark text-white 
                                 px-2 sm:px-4 py-1.5 sm:py-2 
                                 rounded-lg text-xs sm:text-sm font-medium 
                                 transition-default flex items-center gap-1 ml-2">
                    <span class="hidden sm:inline">Ver</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="text-xs text-gray-500 mt-1.5 px-1"
               [ngClass]="message.sender === 'user' ? 'text-right' : 'text-left'">
            {{ message.sender === 'user' ? 'Você' : 'CuponAI' }}
          </div>
        </div>
      </div>

      <!-- Loading Indicator -->
      <div *ngIf="isLoading" class="flex justify-start">
        <div class="bg-white shadow-md text-gray-700 rounded-xl py-2.5 px-4">
          <div class="flex items-center gap-2">
            <div class="w-2.5 h-2.5 bg-primary rounded-full animate-bounce"></div>
            <div class="w-2.5 h-2.5 bg-primary rounded-full animate-bounce delay-100"></div>
            <div class="w-2.5 h-2.5 bg-primary rounded-full animate-bounce delay-200"></div>
            <span class="text-xs ml-2">Carregando...</span>
          </div>
        </div>
      </div>
    </section>

    <!-- Input Area -->
    <footer class="sticky bottom-0  border-t border-gray-100 py-3 md:py-4">
      <div class="container mx-auto px-4 md:px-0">
        <div class="flex items-center space-x-2">
          <input 
            #messageInput
            [(ngModel)]="newMessage" 
            (keyup.enter)="sendMessage()"
            [disabled]="isLoading"
            [placeholder]="chatConfig.placeholder"
            aria-label="Mensagem"
            class="flex-1 px-4 py-2.5 md:py-3 border border-gray-200 rounded-xl 
                   focus:ring-2 focus:ring-primary focus:outline-none 
                   bg-gray-50 text-gray-800 
                   placeholder-gray-500 text-sm md:text-base
                   transition-all duration-300"
          />
          <button 
            (click)="sendMessage()"
            [disabled]="isLoading || !newMessage.trim()"
            aria-label="Enviar mensagem"
            class=" bg-primary text-white px-4 md:px-6 py-2.5 md:py-3 rounded-xl 
                   hover:bg-primary-dark focus:outline-none focus:ring-2 
                   focus:ring-primary focus:ring-offset-2
                   transition-all duration-300 
                   flex items-center gap-2 
                   disabled:opacity-50 disabled:cursor-not-allowed
                   transform hover:scale-105"
                   aria-label="Botão que realiza o envio da mensagem"
          >
            <span class="hidden md:inline">Enviar</span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
            </svg>
          </button>
        </div>
      </div>
    </footer>
  </main>
</div>
