import { parseDef } from "../parseDef.mjs";
function decideAdditionalProperties(def, refs) {
  if (refs.removeAdditionalStrategy === 'strict') {
    return def.catchall._def.typeName === 'ZodNever' ? def.unknownKeys !== 'strict' : parseDef(def.catchall._def, {
      ...refs,
      currentPath: [...refs.currentPath, 'additionalProperties']
    }) ?? true;
  } else {
    return def.catchall._def.typeName === 'ZodNever' ? def.unknownKeys === 'passthrough' : parseDef(def.catchall._def, {
      ...refs,
      currentPath: [...refs.currentPath, 'additionalProperties']
    }) ?? true;
  }
}
export function parseObjectDef(def, refs) {
  const result = {
    type: 'object',
    ...Object.entries(def.shape()).reduce((acc, [propName, propDef]) => {
      if (propDef === undefined || propDef._def === undefined) return acc;
      const parsedDef = parseDef(propDef._def, {
        ...refs,
        currentPath: [...refs.currentPath, 'properties', propName],
        propertyPath: [...refs.currentPath, 'properties', propName]
      });
      if (parsedDef === undefined) return acc;
      return {
        properties: {
          ...acc.properties,
          [propName]: parsedDef
        },
        required: propDef.isOptional() && !refs.openaiStrictMode ? acc.required : [...acc.required, propName]
      };
    }, {
      properties: {},
      required: []
    }),
    additionalProperties: decideAdditionalProperties(def, refs)
  };
  if (!result.required.length) delete result.required;
  return result;
}
